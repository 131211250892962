var asserts = {};

function fail(field, name, obj) {
  throw new Error('[Front] expected ' + field + ' to be ' + name + ' (got ' + obj + ').');
}

['Arguments', 'Function', 'String', 'Number', 'Array', 'Date', 'RegExp', 'Boolean'].forEach(function (name) {
  function test(obj) {
    return Object.prototype.toString.call(obj) === '[object ' + name + ']';
  }

  asserts['opt' + name] = function (obj) {
    return test(obj) ? obj : null;
  };

  asserts['is' + name] = function (obj, field) {
    if (!test(obj))
      fail(field, name, obj);
  };

  asserts['test' + name] = function (obj) {
    return test(obj);
  };
});

asserts.isObject = function(obj, field) {
  var type = typeof obj;

  if (type !== 'function' && (type !== 'object' || !obj))
    fail(field, 'Object', obj);
};

function noop() {}

function Front() {
  this.started = false;
  this.seq = 0;
  this.pendingCb = {};
  this.customTargetWindow = null;
  this.qs = parseQuery();
  this.domain = this.qs.front_domain || 'https://app.frontapp.com';
  this.visible = false;
  this.debug = global.enableFrontDebugMode;
  this.background = global.enableFrontBackgroundMode;
  this.endpoint = location.href.replace(/[?&]auth_secret=.*$/, '');
}

this.heir.inherit(Front, this.EventEmitter);

Front.prototype.init = function (selector) {
  if (!selector)
    return;

  this.customTargetWindow = document.querySelector(selector).contentWindow;
};

Front.prototype._validateDomain = function () {
  // allow https://*.frontapp.com by default
  return global.allowAnyFrontDomain || /^https:\/\/[\w\-]+\.frontapp.com$/.test(this.domain);
};

Front.prototype._connected = function () {
  this.emit(this.started ? 'reconnect' : 'ready');
  this.started = true;
};

Front.prototype._dispatch = function (data) {
  var cb = this.pendingCb[data.seq];
  delete this.pendingCb[data.seq];

  if (cb && typeof(cb) === 'function') {
    cb(data.val);
  }
};

Front.prototype._postCb = function (data, cb) {
  asserts.isFunction(cb, 'cb');

  var seq = ++this.seq;
  data.seq = seq;

  this.pendingCb[seq] = cb;
  this.post(data);
};

Front.prototype.post = function (data) {
  var targetWindow = this.customTargetWindow || global.parent;
  data.endpoint = global.Front.endpoint;
  targetWindow.postMessage(data, this.domain);
};

Front.prototype.dialog = function (type, options, cb) {
  asserts.isObject(options, 'options');
  asserts.isString(options.title, 'options.title');
  asserts.isString(options.message, 'options.message');

  options.type = type;
  options.okTitle = asserts.optString(options.okTitle);
  options.cancelTitle = asserts.optString(options.cancelTitle);
  options.val = asserts.optString(options.val);

  this._postCb(options, cb);
};

['alert', 'confirm', 'prompt', 'report'].forEach(function (type) {
  Front.prototype[type] = function (options, cb) {
    this.dialog(type, options, cb);
  };
});

Front.prototype.promptDate = function (options, cb) {
  asserts.isObject(options, 'options');
  asserts.isObject(options.position, 'options.position');
  asserts.isNumber(options.position.left, 'options.position.left');
  asserts.isNumber(options.position.top, 'options.position.top');

  options.type = 'prompt_date';

  ['val', 'before', 'after'].forEach(function (field) {
    if (options[field]) {
      asserts.isDate(options[field], 'options.' + field);
      options[field] = options[field].getTime();
    }
  });

  this._postCb(options, function (ts) {
    cb(ts && new Date(ts));
  });
};

Front.prototype.fuzzylist = function (options, cb) {
  asserts.isObject(options, 'options');
  asserts.isArray(options.items, 'options.items');

  options.items.forEach(function (item) {
    asserts.isString(item.title);
    asserts.optString(item.completion);
  });

  options.type = 'fuzzylist';

  this._postCb(options, cb);
};

Front.prototype.copyToClipboard = function (payload) {
  this.post({type: 'copy_clipboard', payload: payload});
};

Front.prototype.openUrl = function (url) {
  this.post({type: 'open_url', url: url});
};

Front.prototype.openPopupUrl = function (url, title) {
  this.post({type: 'open_popup_url', url: url, title: title});
};

Front.prototype.openPopup = function (url, title) {
  var self = this;

  this._postCb({type: 'will_open_popup'}, function () {
    self._lastPopup = global.open(url, title, 'menubar=no,title,resize,location=no,width=650,height=530');
  });
};

Front.prototype.closePopup = function () {
  if (this._lastPopup) {
    this._lastPopup.close();
    this._lastPopup = null;
  }
};

Front.prototype.search = function (query) {
  this.post({type: 'search', query: query});
};

global.Front = new Front();

if (!global.Front._validateDomain()) {
  global.Front.log('error', '[Front] could not validate host domain ' + global.Front.domain);
  global.Front = null;
}

function parseQuery() {
  var query = window.location.search.substring(1),
      vars = query.split('&'),
      qs = {};

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');

    if (pair.length === 2)
      qs[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return qs;
}

var eventsToLog = ['conversation', 'no_conversation', 'panel_visible'];
global.addEventListener('message', function (event) {
  // It must originate from https://*.frontapp.com (browser) or ourselves (injected by React Native app).
  if (event.origin !== global.Front.domain && event.origin !== global.origin) {
    global.Front.log('error', 'Received event from unexpected origin ' + event.origin);
    return;
  }

  var eventData = event.data;
  var eventType = eventData.type;

  if (eventsToLog.indexOf(eventType) >= 0)
    global.Front.log('info', 'Received ' + eventType + ' event.');

  switch(eventType) {
    case 'sync':
      global.Front._connected();
      break;

    case 'user':
      global.Front.user = eventData.user;
      break;

    case 'conversation':
      global.Front.emit('conversation', eventData);
      break;

    case 'no_conversation':
      global.Front.emit('no_conversation');
      break;

    case 'panel_visible':
      global.Front.visible = eventData.visible;
      global.Front.emit('panel_visible', eventData.visible);
      break;

    case 'done':
      global.Front._dispatch(eventData);
      break;
  }
});

global.addEventListener('click', function (event) {
  // nice try, Firefox…
  if (event.button !== 0 || global.parent === global)
    return;

  // If we have something like: <a href="xxx"> <strong>Label</strong> </a>, the <strong> might
  // catch the click event and we need to crawl the DOM to find the <a>.
  function findHref(element, depth) {
    if (!element || depth === 20)
      return null;

    if (element.tagName !== 'A' || !element.href)
      return findHref(element.parentElement, depth + 1);

    return element;
  }

  var link = findHref(event.target, 0);

  if (link) {
    // _blank opens in external window
    if (link.target !== '_blank')
      return;

    event.preventDefault();
    event.stopPropagation();

    global.Front.openUrl(link.href);
  }
});

// Tell the app that the library is ready to receive message
function init() {
  if (!global.Front)
    return;

  global.Front.post({type: 'plugin_connected', debug: global.Front.debug, background: global.Front.background});
  global.Front.log('info', 'Connecting...');
}

if (document.readyState !== 'loading')
  setTimeout(init, 0);
else
  document.addEventListener('DOMContentLoaded', init);

// * GETTERS

Front.prototype.fetchConversation = function (callback) {
  this._postCb({type: 'fetch_conversation'}, callback);
};

Front.prototype.fetchTeam = function (callback) {
  this._postCb({type: 'fetch_team'}, callback);
};

Front.prototype.fetchAllowedTeammates = function (callback) {
  this._postCb({type: 'fetch_allowed_teammates'}, callback);
};

Front.prototype.fetchInboxes = function (callback) {
  this._postCb({type: 'fetch_inboxes'}, callback);
};

Front.prototype.fetchChannels = function (callback) {
  this._postCb({type: 'fetch_channels'}, callback);
};

Front.prototype.fetchAllowedTags = function (callback) {
  this._postCb({type: 'fetch_tags'}, callback);
};

Front.prototype.fetchNotes = function (callback) {
  this._postCb({type: 'fetch_notes'}, callback);
};

Front.prototype.fetchDraft = function (callback) {
  this._postCb({type: 'fetch_draft'}, callback);
};

// Non-documented method to fetch the PATH of the parent window.
Front.prototype.fetchAppPath = function (callback) {
  this._postCb({type: 'fetch_app_path'}, callback);
}

// * HELPERS
// *
// * conversation is not used yet, but will be soon

Front.prototype.unassign = function (conversation) {
  this.post({
    type: 'unassign',
    conversation_id: conversation ? conversation.id : null
  });
};

Front.prototype.assign = function (assignee, conversation) {
  var assigneeAlias = toAlias(assignee);

  this.post({
    type: 'assign',
    assignee: assigneeAlias,
    conversation_id: conversation ? conversation.id : null
  });
};

Front.prototype.attachTag = function (tag, conversation) {
  var tagAlias = toAlias(tag);

  this.post({
    type: 'tag',
    tag: tagAlias,
    conversation_id: conversation ? conversation.id : null
  });
};

Front.prototype.detachTag = function (tag, conversation) {
  var tagAlias = toAlias(tag);

  this.post({
    type: 'untag',
    tag: tagAlias,
    conversation_id: conversation ? conversation.id : null
  });
};

Front.prototype.toggleTag = function (tag, conversation) {
  var tagAlias = toAlias(tag);

  this.post({
    type: 'toggle_tag',
    tag: tagAlias,
    conversation_id: conversation ? conversation.id : null
  });
};

Front.prototype.toggleArchive = function (conversation) {
  this.post({
    type: 'toggle_archived',
    conversation_id: conversation ? conversation.id : null
  });
};

Front.prototype.toggleTrashed = function (conversation) {
  this.post({
    type: 'toggle_trashed',
    conversation_id: conversation ? conversation.id : null
  });
};

Front.prototype.moveToInbox = function (inbox) {
  var inboxAlias = toAlias(inbox);

  this.post({
    type: 'move_to_inbox',
    inbox: inboxAlias,
    inbox_alias: inboxAlias
  });
};

Front.prototype.addTopic = function (topic, cb) {
  asserts.isObject(topic);
  asserts.optString(topic.type);
  asserts.optString(topic.name);
  asserts.isString(topic.ext_link);

  this._postCb({type: 'add_topic', topic: topic}, cb);
};

Front.prototype.reply = function (options, replyAll, conversation, callback) {
  options = options || {};

  // conversation is optionnal
  if (!callback && typeof conversation === 'function')
    callback = conversation;

  callback = callback || noop;

  asserts.isObject(options);
  asserts.optString(options.body);
  asserts.optString(options.html);
  asserts.optString(options.text);
  asserts.optString(options.subject);
  asserts.optBoolean(options.send_now);
  asserts.optBoolean(options.mark_archived);
  asserts.optArray(options.to);
  asserts.optArray(options.cc);
  asserts.optArray(options.bcc);
  asserts.optArray(options.attachment_uids);

  var tags = [];

  if (options.tags) {
    asserts.isArray(options.tags);

    options.tags.forEach(function (tag) {
      tags.push(toAlias(tag));
    });
  }

  this._postCb({
    type: replyAll ? 'reply_all' : 'reply',
    tags: tags,
    body: makeBody(options),
    subject: options.subject || null,
    send_now: options.send_now || false,
    mark_archived: options.mark_archived || null,
    to: options.to || null,
    cc: options.cc || null,
    bcc: options.bcc || null,
    conversation_id: conversation ? conversation.id : null,
    attachment_uids: options.attachment_uids || null
  }, callback);
};

// Deprecated
Front.prototype.forwardTo = function (handle, options) {
  options = options || {};

  asserts.optString(handle);
  asserts.optString(options.body);
  asserts.optString(options.subject);

  this.post({
    type: 'forward',
    body: makeBody(options),
    subject: options.subject || null,
    send_now: options.send_now || false,
    mark_archived: options.mark_archived || null,
    to: handle
  });
};

Front.prototype.forward = function (options, callback) {
  options = options || {};
  callback = callback || noop;

  asserts.optArray(options.to);
  asserts.optArray(options.cc);
  asserts.optArray(options.bcc);
  asserts.optString(options.body);
  asserts.optString(options.subject);
  asserts.optArray(options.attachment_uids);

  this._postCb({
    type: 'forward',
    body: makeBody(options),
    subject: options.subject || null,
    send_now: options.send_now || false,
    mark_archived: options.mark_archived || null,
    to: options.to || null,
    cc: options.cc || null,
    bcc: options.bcc || null,
    attachment_uids: options.attachment_uids || null
  }, callback);
}

Front.prototype.compose = function (options, callback) {
  options = options || {};
  callback = callback || noop;

  asserts.isObject(options);
  asserts.optArray(options.to);
  asserts.optArray(options.cc);
  asserts.optArray(options.bcc);
  asserts.optString(options.from);
  asserts.optString(options.subject);
  asserts.optString(options.body);
  asserts.optBoolean(options.send_now);
  asserts.optBoolean(options.hide_composer);
  asserts.optBoolean(options.mark_archived);
  asserts.optArray(options.attachment_uids);

  var inboxAlias = toOptAlias(options.inbox),
      tags = [];

  if (options.tags) {
    asserts.isArray(options.tags);

    options.tags.forEach(function (tag) {
      tags.push(toAlias(tag));
    });
  }

  this._postCb({
    type: 'compose',
    from: options.from || null,
    to: options.to || null,
    cc: options.cc || null,
    bcc: options.bcc || null,
    body: makeBody(options),
    subject: options.subject || null,
    send_now: options.send_now || false,
    hide_composer: options.hide_composer,
    mark_archived: options.mark_archived || null,
    specifiedInboxAlias: inboxAlias,
    tags: tags,
    attachment_uids: options.attachment_uids || null
  }, callback);
};

Front.prototype.insertHTML = function (html) {
  asserts.isString(html);
  this.post({type: 'insert_html', html: html});
};

Front.prototype.updateDraft = function (draftId, options, callback) {
  options = options || {};
  callback = callback || noop;

  asserts.isString(draftId);
  asserts.isObject(options);
  if (options.recipients) {
    asserts.optArray(options.recipients.to);
    asserts.optArray(options.recipients.cc);
    asserts.optArray(options.recipients.bcc);
  }
  asserts.optArray(options.attachment_uids);

  this._postCb({
    type: 'update_draft',
    draft_id: draftId,
    recipients: options.recipients || null,
    attachment_uids: options.attachment_uids || null
  }, callback);
};

Front.prototype.setPanelWidth = function (width) {
  asserts.isNumber(width);
  this.post({type: 'set_width', width: width});
};

var availableLogLevel = ['log', 'info', 'error', 'warn'];
Front.prototype.log = function (level, message) {
  if (!this.debug || availableLogLevel.indexOf(level) < 0 || !global.console)
    return;

  global.console[level]('[Front] Plugin (' + this.endpoint + '): ', message);
};

function toOptAlias(obj) {
  return obj ? toAlias(obj) : null;
}

function toAlias(obj) {
  if (asserts.testString(obj))
    return obj;

  asserts.isObject(obj);
  asserts.isString(obj.alias);

  return obj.alias;
}

function makeBody(options) {
  if (options.html) {
    return options.html;
  } else if (options.body) {
    return options.body;
  } else if (options.text) {
    var div = document.createElement('div');
    div.innerText = options.text;

    return div.innerHTML;
  } else {
    return '';
  }
}
